<template>
  
     <router-view/>
</template>
<style>
html {
  scroll-behavior: smooth;
}


</style>
