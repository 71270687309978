<template>
  <div  class="section bg-footer" :class="$root.$i18n.locale" id="contact">
    <div class="d-flex align-items-center flex-mobile-column">
      <img
        :src="require(`@/assets/img/${getImg}`)"
        alt="althaalab almacker pattern"
        class="h-100vh bg-light-grey d-none d-xl-block"/>
      <h2
        class="mulish f-150 black white line-height-1 text-uppercase text-mobile-center">
        {{ $t('sixth-title-line1') }}
        <br />
        {{ $t('sixth-title-line2') }}
      </h2>
      <div class="d-flex flex-mobile-column">
        <letsConnectForm />
        <addresses />
      </div>
    </div>
  </div>
</template>
<script>
import letsConnectForm from '../shared/letsConnectForm.vue'
import addresses from '../shared/addresses.vue'
export default {
  components: {
    letsConnectForm,
    addresses
  },
  computed: {
    getImg () {
      if (this.$i18n.locale == 'ar') 
        return 'lets-connect-ar.svg'
      return 'lets-connect.svg'
    }
  }
}
</script>
<style scoped>
.ar.section {
  direction: rtl;
}

.h-100vh {
  height: 100vh;
}

.bg-footer {
  background-color: #9e2600;
}

@media (max-width: 1199.98px) {
  .ar .f-150 {
    font-size: 75px;
}
  .text-mobile-center {
    text-align: center;
  }

  .bg-footer {
    background-image: url('../../assets/img/footer-bg.svg');
    background-size: 100%;
    background-position-y: top;
    background-position-x: center;
    background-repeat: no-repeat;
    padding-top: 33vh;
  }
}
</style>
