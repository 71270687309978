<template>
    <form class="mx-10vw">
        <div class="d-flex justify-content-evenly mb-2 flex-mobile-column">
            <div class="form-floating mb-xl-0 mb-3 mb-md-5  w-45">
                <input type="text" class="form-control f-25 px-0 mulish regular" id="firstName" placeholder="First Name">
                <label for="firstName" class="text-captilize f-25 px-0 mulish regular">{{$t('sixth-form-input1')}} </label>
            </div>
            <div class="form-floating mb-xl-0 mb-3  w-45  mb-md-5">
                <input type="text" class="form-control f-25 px-0 mulish regular" id="lastName" placeholder="Last Name">
                <label for="lastName" class="text-captilize f-25 px-0 mulish regular">{{$t('sixth-form-input2')}}</label>
            </div>
        </div>
        <div class="d-flex justify-content-evenly mb-2 flex-mobile-column">
            <div class="form-floating mb-xl-0 mb-3 w-45 mb-md-5">
                <input type="text" class="form-control f-25 px-0 mulish regular" id="company" placeholder="Company">
                <label for="firstName" class="text-captilize f-25 px-0 mulish regular">{{$t('sixth-form-input3')}}</label>
            </div>
            <div class="form-floating mb-xl-0 mb-3  w-45 mb-md-5">
                <input type="text" class="form-control f-25 px-0 mulish regular" id="Country" placeholder="Country/Region">
                <label for="lastName" class="text-captilize f-25 px-0 mulish regular">{{$t('sixth-form-input4')}}</label>
            </div>
        </div>
        <div class="d-flex justify-content-evenly mb-2 flex-mobile-column">
            <div class="form-floating mb-xl-0 mb-3 w-45  mb-md-5">
                <input type="text" class="form-control f-25 px-0 mulish regular" id="Phone" placeholder="Phone Number">
                <label for="Phone" class="text-captilize f-25 px-0 mulish regular">{{$t('sixth-form-input5')}}</label>
            </div>
            <div class="form-floating mb-xl-0 mb-3  w-45  mb-md-5 ">
                <input type="email" class="form-control f-25 px-0 mulish regular" id="email" placeholder="email">
                <label for="email" class="text-captilize f-25 px-0 mulish regular">{{$t('sixth-form-input6')}}</label>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="form-floating mb-xl-0 mb-3 w-93">
                <textarea class="form-control f-25 px-0 mulish regular" placeholder="Message" id="message"></textarea>
                <label for="message" class="text-captilize f-25 px-0 mulish regular">{{$t('sixth-form-input7')}}</label>
            </div>
        </div>
        <hr class="my-10 d-xl-none d-block"> 
        <button type="submit"
            class="ms-xl-auto-mx-auto submit mr-10 btn btn-secondary  bg-white orange back f-18 f-15 bold mulish d-flex align-items-center justify-content-center mb-3 mb-xl-0">
            {{$t('sixth-form-submit')}}
        </button>
    </form>
</template>
<style scoped>
.arabic .form-floating>.form-control-plaintext~label,.arabic  .form-floating>.form-control:focus~label,.arabic  .form-floating>.form-control:not(:placeholder-shown)~label,.arabic  .form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.85) translateY(0rem) translateX(4rem);
}
.ms-xl-auto-mx-auto{
    margin-left:auto;
}
.mr-10 {
    margin-right: 5%;
    margin-top: 3%;
}

.submit {
    height: 44px;
    width: 175px;
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: 1;
}

.mx-10vw {
    margin-right: 10vw;
    margin-left: 10vw;
}

.w-93 {
    width: 90%;
}

form {
    width: 58.5vw
}

.w-45 {
    width: 43%;
}

.form-control,
.form-control:focus,
.form-control:active {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #F3F3F3;
    color: #F3F3F3;
    box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
    height: 68px !important;
}

label {
    color: #F3F3F3;
}

@media (max-width: 1399.98px) {
    .submit {
        height: 38px;
        width: 145px;
    }
}

@media (max-width: 1199.98px) {
    .f-15{
        font-size: 15px;
    }
    .ms-xl-auto-mx-auto{
    margin-right:auto;
    margin-left:auto;
    margin-top: 46px;
}
.my-10{
    margin-top: 10%;
    margin-bottom: 10%;
    margin-top: 10%;
    margin-bottom: 10%;
    border-bottom: 3px solid white;
}
    .w-93 {
    width: 99%;
}
    .w-45 {
        width: 100%;
    }
    .mx-10vw{
    margin-right: auto;
    margin-left: auto;
    width: 80%;
}
.text-mobile-center{
    text-align: center;
}
}
</style>
