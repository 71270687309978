<template>
  <div 
   class="section bg-light-grey ml-11"  :class="$root.$i18n.locale" id="we-worked-with">
    <div class="d-flex h-100 w-100 align-items-center flex-mobile-column">
      <div class="text bg-red d-flex flex-column align-items-center justify-content-center position-relative d-xl-none d-block">
        <p class="mb-0 position-absolute f-148 w-100 text-center mulish black line-height-1 white red text-uppercase t-0 b-0 border-50 mt-3 mt-xl-0">
          {{ $t("fourth-W") }}{{ $t("fourth-E") }}
          <span class="d-xl-none d-block en-none">{{ $t("fourth-worked") }}</span></p>
          <img src="../../assets/img/we-worked-with-bg.svg" class="w-100vw" alt="althaalab almacker pattern">
       
        <p class="ar-none f-70 mulish black white text-uppercase  text-center line-height-1 my-xl-5 my-3 ">
        {{ $t('fourth-line1') }} <br/> {{ $t('fourth-line2') }}
        </p>
        <p class=" f-70 mulish black en-none white text-uppercase with text-center line-height-1 my-xl-5 my-3 ">
        {{ $t('fourth-with') }}
        </p>
        <svg viewBox="0 0 100 100"  class="mulish light-orange f-12 bold text-uppercase rotate">
            <defs>
              <path id="circle" d="
                                  M 50, 50
                                  m -37, 0
                                  a 37,37 0 1,1 74,0
                                  a 37,37 0 1,1 -74,0" />
            </defs>
            <text>
              <textPath xlink:href="#circle">
                {{ $t("fourth-circle") }}
              </textPath>
            </text>
          </svg>
      </div>
      <p class="mb-0 f-375 mulish black red d-xl-block d-none"> {{ $t("fourth-W") }}   </p>
      <div class="d-flex position-relative bg-red">
        <img :src="require(`@/assets/img/${getLangImg}`)"  alt="althaalab almacker pattern" class="h-100vh d-none d-xl-block" />
        <div
          class="d-flex align-items-center position-absolute t-0 b-0 l-10  align-items-center  d-xl-flex d-none">
          <div class="d-flex">
             <p class="mb-0 f-375 mulish black white mr-10 e"> {{ $t("fourth-E") }}   </p>
          <div class="position-relative">
            <p class="mb-0 f-375 mulish black white mr-10 e hidden"> {{ $t("fourth-E") }}  </p>
            <div class="b-0 t-0 d-flex flex-column justify-content-between ar-justify-content-center  position-absolute mt-2 ar-r-25px">
               <p class="mb-0 f-150 line-height-5 mulish black white text-uppercase ar-no-wrap">
                {{ $t("fourth-line1") }} 
          </p>
          <p class="mb-0 f-150 line-height-5 mulish black white text-uppercase mt-2">
            {{ $t("fourth-line2") }}  
          </p>
            </div>
           
          </div>
         
          </div>
        
          <svg viewBox="0 0 100 100"  class="mulish light-orange f-12 bold text-uppercase rotate">
            <defs>
              <path id="circle" d="
                                  M 50, 50
                                  m -37, 0
                                  a 37,37 0 1,1 74,0
                                  a 37,37 0 1,1 -74,0" />
            </defs>
            <text>
              <textPath xlink:href="#circle">
                &nbsp;&nbsp;&nbsp;
                {{ $t("fourth-circle") }}     
                &nbsp;&nbsp;&nbsp;
                {{ $t("fourth-circle") }} 
                       </textPath>
            </text>
          </svg>
        </div>


      </div>
      <div class="bg-red h-100 d-flex flex-mobile-column pt-5 pt-xl-0">
        <div class="min-width-100vw h-100">
          <logos />
        </div>
        <img :src="require(`@/assets/img/${getImg}`)" alt="althaalab almacker pattern" class="img-h-100" />
      </div>

    </div>
  </div>
</template>

<script>
import logos from '../shared/logos.vue'
export default {
  components: {
    logos
  }
  ,
  data() {
    return {
      screen: window.innerWidth
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods:{
    onResize(){
      this.screen = window.innerWidth ;
    }
  },
  computed:{
    getImg(){
      if(this.screen < 1200)
         return 'zom.svg';  
      if(this.$i18n.locale == 'en')
        return 'half-m.svg' ;
      return 'half.svg';



    },
    getLangImg(){
      if(this.$i18n.locale == 'en')
        return 'half.svg' ;
      return 'half-m.svg';
    }
  }
}
</script>
<style scoped>
.en .en-none {
  display: none!important;
}
.ar .ar-r-25px{
  right: 25px;
}
.ar .ar-justify-content-center{
  justify-content: center!important;
}
.ar.section{
    direction: rtl;
}
.ar .f-12 {
    font-size: 9px;
}
.ar .ar-none{
  display: none;
}
.en .en-none{
  display: none;
}
.hidden{
  visibility: hidden;
}
.line-height-5{
  line-height: .85;
}
.img-h-100{
  height: 100%;
}
.ar .l-10{
    right: 2%;
    left: 0;
}
.ar .ar-no-wrap{
  white-space: nowrap;
}

svg {
    position: absolute;
    left: 144%;
    top: 51%;
}
.ar svg{
  left: -120%;
  top: 61%;
  direction: ltr;
}
.e{
  line-height: 0.79;
}
.border-radius-250 {
  border-radius: 200%;
}

.bg-red {
  background-color: #9e2600;
}

.light-orange {
  fill: #ff8657;
}

.red {
  color: #9e2600;
}

.l-10 {
  left: 2%;
}

.mr-10 {
  margin-right: 2%;
}

.min-width-100vw {
  min-width: 100vw;
}

.ml-11 {
  margin-left: 11.97vw;
}
.ar.ml-11 {
  margin-right: 11.97vw;
  margin-left:0
}

.ar .rotate {
  animation: rotation-ar 7s infinite linear;
}
.en .rotate{
    animation: rotation 7s infinite linear;

}

@keyframes rotation {
  from {
    transform: rotate(0deg);
    transform-origin: center center;
  }

  to {
    transform: rotate(-360deg);
    transform-origin: center center;
  }
}
@keyframes rotation-ar {
  from {
    transform: rotate(0deg);
    transform-origin: center center;
  }

  to {
    transform: rotate(360deg);
    transform-origin: center center;
  }
}
@media (max-width: 1199.98px) {
  .ar .f-12 {
    font-size: 16px;
}
  .ar .f-70{
    font-size: 116px;
}
  .ar .f-148 {
    font-size: 105px;
}
  .ar.ml-11 {
  margin-right: 0;
  margin-left:0
}

  .hidden{
    visibility: hidden;
}
  .img-h-100{
  width: 100%;
  height: unset;
  margin-top: 2.5rem;
}
  .ml-25 {
    margin-left: 0;
    margin-right: 0;
  }

  .ml-11 {
    margin-left: 0;
  }
  .bg-we-worked-with{
    background-image: url('../../assets/img/we-worked-with-bg.svg');
    background-size: contain;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }
  .f-148{
    font-size: 134px;
}
  .f-70{
    font-size: 70px;
  }
  .border-50{
    /* height: 24vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    
  }
  svg{
    position: absolute;
    right: 8%;
    bottom: -3%;
    left: unset;
    top: unset;
}
}
</style>