<template>
  <div  
  class="section bg-light-grey" :class="$root.$i18n.locale" id="our-approach">
    <div class="d-flex align-items-center flex-mobile-column">
      <img
        src="../../assets/img/our-approach.svg"
        class="h-100vh d-xl-block d-none our-approuch-pattern"
        alt="althaalab almacker pattern"
      />
      <div class="d-flex flex-column our-approach-title">
        <img
          src="../../assets/img/our-approach-o.svg"
          alt="althaalab almacker pattern"
          class="w-25 mx-3 d-xl-none d-flex ar-pattern-style"
        />
        <h2
          class="f-200 line-height-4 mulish black ar-orange text-uppercase ml-2 position-relative mr-15 ar-mr-mobile-15"
        >
          {{ $t("third-title1") }}
          <p
            class="f-95 mulish orange black text-uppercase mb-0 position-ds-absolute approach ar-none"
          >
            {{ $t("third-title2") }}
          </p>
        </h2>
      </div>
      <div class="d-flex flex-column w-34">
        <div
          class="our-approach-text p mb-xl-5 mb-3 mt-3 mt-xl-0 mulish f-40 light line-height-2 pb-xl-4 pb-0"
        >
          {{ $t("third-content1") }}
        </div>
        <div class="our-approach-text p mb-0 mulish f-40 light line-height-2">
          {{ $t("third-content2") }}
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.ar .ar-pattern-style{
  margin-right: auto!important;
    transform: rotate(93deg);
    margin-left: 12vw!important;
    margin-bottom: -20%;
}
.ar .our-approuch-pattern {
  transform: scaleX(-1);
}
.ar .ar-none{
  display: none;
}
.ar.section {
  direction: rtl;
}

.ar .ar-orange{color:#D6572B}
.hidden {
  visibility: hidden;
}
.position-ds-absolute {
  position: absolute;
}

.approach {
  bottom: -30%;
  left: 23%;
}

.h-100vh {
  height: 100vh;
}

.w-34 {
  width: 34vw;
}

.en .mr-15 {
  margin-right: 15.5vw;
}
.ar .mr-15 {
  margin-right: -2%;
  margin-left: 15.5vw;
}
.en .ml-2 {
  margin-left: -2%;
}

@media (max-width: 1199.98px) {
  .ar .our-approach-text{
    line-height: 1.7;
    font-weight: 300!important;
  }
  .ar .ar-mr-mobile-15{
    margin: 0!important;
    line-height: 1.4!important;
    color: #d3592d!important;
    font-size: 130px;
  }
  .our-approach-text {
    font-weight: 400 !important;
  }
  .our-approach-title h2.line-height-4 {
    line-height: 0.8;
    color: #373e43;
  }
  .line-height-sm-1 {
    line-height: 0.8;
  }
  .approach {
    margin-top: 1rem;
    bottom: unset;
    left: unset;
    text-align: right;
  }
  .w-34 {
    width: 85%;
  }

  .f-95 {
    font-size: 40px;
  }

  .f-200 {
    font-size: 148px;
  }

  .ml-2 {
    margin-left: 0%;
  }

  .mr-15 {
    margin-right: 0;
  }

  .position-ds-absolute {
    position: relative;
  }
  .mx-7 {
    margin-left: 7.5%;
    margin-right: auto;
  }
  .f-40 {
    font-size: 20px;
  }
  .en .mr-15 {
    margin-right: 0;
}
}
</style>
