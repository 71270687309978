<template>
  <div
    class="container-fluid position-fixed t-0 b-0 l-0 r-0 z-11 bg-light-grey layout"
    :class="
    $root.$i18n.locale +
      (isBack
        ? ' animate__slideOutLeft animate__animated'
        : ' animate__slideInLeft animate__animated')
    "
  >
    <div class="row bg-light-grey h-100">
      <div class="col-xl-3 col-12 bg-red px-0">
        <img
          :src="require(`@/assets/img/${imgSrc}`)"
          class="h-ds-100vh max-w-100 mx-auto"
          alt="althaalab almacker pattern"
        />
      </div>
      <div
        class="col-xl-6 col-10 mx-auto me-xl-auto offset-1 px-0 d-flex flex-column align-items-ds-center-mobile-start justify-content-center text-start my-mobile-3"
      >
        <h1
          class="title f-80 black mulish text-uppercase text-start me-auto light-black line-height-1"
        >
          <span>{{ title }}</span>
        </h1>
        <p class="mb-0 f-35 mulish bold mt-6 light-black line-height-13 desc">
          {{ desc }}
        </p>
        <div class="list d-flex align-items-center flex-mobile-column flex-wrap mt-9"  v-if="list.length!==0">
          <div
            class="d-flex w-50"
            v-for="(item, index) in list"
            v-bind:key="index"
            :class="{
              'mb-xxl-3 mb-xl-2': index + 1 != list.length && index + 2 != list.length,
            }"
          >
            <div class="point me-3"></div>
            <p class="f-30 mulish regular mb-0 light-black">
              {{ item }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-11 mx-auto d-flex align-items-end">
        <button
          @click="goBack"
          type="button"
          class="mt-xl-3 mb-xl-5 mb-auto btn btn-primary back f-18 medium mulish d-flex align-items-center ms-auto justify-content-center animate__animated animate__fadeInRight"
        ><span class="f-30 mx-1 ar-none">&#x2190;</span>
          {{ $t("back") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      back: false,
    };
  },
  computed: {
    isBack() {
      return this.back;
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    imgSrc: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: "",
    }
  },
  methods: {
    goBack() {
      var here = this;
      this.back = true;
      setTimeout(function () {
        here.$emit("clicked-show-detail");
      }, 500);
    },
  },
};
</script>
<style scoped>
.ar .ar-none {
  display: none;
}
.ar {
  direction: rtl;
}
.ar .align-items-ds-center-mobile-start {
  align-items: start;
}
.ar h1 {
  margin-right: 0 !important;
  margin-left: auto;
  text-align: right!important;
}
.ar .point {
  margin-left: 1rem !important;
  margin-right: 0rem !important;
}
.ar p {
  text-align: right;
  font-weight: 600 !important;
  line-height: 1.6;
}
.z-11 {
  z-index: 11;
}

.align-items-ds-center-mobile-start {
  align-items: center;
}

.h-ds-100vh {
  height: 100vh;
}

.back {
  height: 44px;
  width: 175px;
}

.mt-9 {
  margin-top: 9.2vh;
}

.mt-6 {
  margin-top: 4.6vh;
}

.bg-red {
  background-color: #9e2600;
}

.max-w-100 {
  max-width: 100%;
}

.point {
  border: 6px solid #485258;
  border-radius: 100px;
  height: 16px;
  width: 16px;
  display: flex;
  align-self: center;
}

.line-height-13 {
  line-height: 1.3;
}

@media (max-width: 1399.98px) {
  .mt-9 {
    margin-top: 7.2vh;
  }

  .mt-6 {
    margin-top: 3.6vh;
  }

  .back {
    height: 38px;
    width: 145px;
  }
}

@media (max-width: 1199.98px) {
  .bg-red {
    display: flex;
    align-self: self-start;
  }
  .my-mobile-3 {
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .layout {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .mt-6,
  .mt-9 {
    margin-top: 3%;
  }
  .f-80 {
    font-size: 30px;
  }
  .layout .f-30 {
    font-size: 16px;
  }
  .back {
    height: unset;
  }
  .layout .desc {
    font-weight: 400 !important;
  }

  .overflow-y-auto-mobile {
    overflow-y: auto;
  }
  .align-items-ds-center-mobile-start {
    align-items: start;
  }

  .list .w-50 {
    width: 100% !important;
  }

  .h-ds-100vh {
    height: unset;
    width: 100%;
  }
}
</style>
