<template>
  <div class="d-flex flex-mobile-column ml-25" id="logos-container">
    <div class="d-flex  three-logo-container hidden" id="a">
      <div class="logo bg-light-red text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/jumlatec.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental horizental-start" />
      </div>
      <div class="logo text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/ecart.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental horizental-start" />
      </div>
      <div class="logo bg-light-red text-center d-flex">
        <img
          src="../../assets/img/clients/stars.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
      </div>
    </div>
    <hr class="vertical hidden" />
    <div class="d-flex  three-logo-container hidden" id="b">
      <div class="logo text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/dybak.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental d-xl-block d-none" />
      </div>
      <div class="logo bg-light-red text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/skyjoud.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental" />
      </div>
      <div class="logo text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/bemo.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental d-xl-none d-block" />
      </div>
    </div>
    <hr class="vertical hidden" />
    <div
      class="d-flex  three-logo-container hidden position-relative"
      id="c"
    >
      <div class="logo bg-light-red text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/ehjizli.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental d-xl-block d-none" />
      </div>
      <div class="logo text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/fatora.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental" />
      </div>
      <div class="logo bg-light-red text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/albaraka.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental d-xl-none d-block" />
      </div>
    </div>
    <hr class="vertical hidden" />
    <div class="d-flex  three-logo-container hidden">
      <div class="logo text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/kaffou.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental d-xl-block d-none" />
      </div>
      <div class="logo bg-light-red text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/jabbour.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental" />
      </div>
      <div class="logo text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/easybuy.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental d-xl-none d-block" />
      </div>
    </div>
    <hr class="vertical hidden" />
    <div class="d-flex  three-logo-container hidden">
      <div class="logo bg-light-red text-center d-flex position-relative">
        <hr class="horizental d-xl-block d-none" />
        <img
          src="../../assets/img/clients/udesign.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
      </div>
      <div class="logo text-center d-flex position-relative">
        <hr class="horizental" />
        <img
          src="../../assets/img/clients/fashiongala.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
      </div>
      <div class="logo bg-light-red text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/taj.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental d-xl-none d-block" />
      </div>
    </div>
    <hr class="vertical hidden" />
    <div class="d-flex  three-logo-container hidden">
      <div class="logo text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/modarsalama.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental d-xl-block d-none" />
      </div>
      <div class="logo bg-light-red text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/gemini.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental" />
      </div>
      <div class="logo text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/citione.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental d-xl-none d-block" />
      </div>
    </div>
    <hr class="vertical hidden" />
    <div class="d-flex  three-logo-container hidden">
      <div class="logo bg-light-red text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/keytime.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental horizental-end d-xl-block d-none" />
      </div>
      <div class="logo text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/study.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental horizental-end" />
      </div>
      <div class="logo bg-light-red text-center d-flex position-relative">
        <img
          src="../../assets/img/clients/mowara.svg"
          class="max-width-100 m-auto d-flex"
          alt="client logo"
        />
        <hr class="horizental horizental-end d-xl-none d-block" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-light-red {
  background-color: #aa2f08;
}


.logo {
  height: 33.333vh;
  width: 33.33vh;
}

.max-width-100 {
  max-width: 90%;
}
.ar .ml-25 {
  margin-right: 34vw;
  margin-left: 5vw;
}
.ml-25 {
  margin-left: 25vw;
  margin-right: 5vw;
}
.three-logo-container{
  flex-direction: column;
}
.three-logo-container::after {
  content: "";
  display: flex;
  border-right: 1px solid #f3f3f3;
}

.vertical {
  margin: auto 0;
  border-right: 2px solid #f3f3f3;
  width: 0px;
  height: 80vh;
}

.horizental {
  margin: 0;
  border-bottom: 2px solid #f3f3f3;
  height: 0px;
  /* width: 33.33vh; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.horizental-start {
  width: 19vh;
  right: 0;
  left: auto;
}

.horizental-end {
  width: 19vh;
  left: 0;
  right: auto;
}
.hidden{
  visibility: hidden;
}
@media (max-width: 1199.98px){
  .ar .ml-25 {
  margin-right: 0;
  margin-left: 0;
}
  .hidden{
  visibility: visible;
}
  .three-logo-container{
  flex-direction: row;
}

.logo {
  height: 33.333vw;
  width: 33.33vw;
}
.vertical{
    margin: 0 auto;
    border-right:none;
    height: 0px;
    width: 80vw;
    opacity: .3;
    border-top: 2px solid #f3f3f3;
}
.horizental {
  margin: 0;
    border-right: 2px solid #f3f3f3;
    width: 0px;
    height: 100%;
    position: absolute;
    right: 0;
}
.horizental-start {
  height: 50%;
  right: -2px;
  left: auto;
  bottom: 0;
}

.horizental-end {
  height: 50%;
  left: 0;
  right: 0px;
  top: 0;
}
}
</style>