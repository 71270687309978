import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css.css"
import "bootstrap/dist/css/bootstrap.min.css"


import "bootstrap"
import 'animate.css'
import 'jquery.scrollto'
import i18n from './i18n'
import JQuery from 'jquery'

window.$ = JQuery
createApp(App).use(i18n).use(store).use(router).mount('#app')
