<template>
  <div
    class="section curve z-2 bg-light-black mulish position-relative text-start h-ds-100vh-mb-87"
    :id="lang + '-home'"
    :class="(lang==$i18n.locale)&&mobile ? lang+' flex-column justify-content-evenly overflow-hidden d-flex':lang"
 >
    <svg
      v-if="!mobile"
      xmlns="http://www.w3.org/2000/svg"
      class="position-absolute t-0 r-0 w-30 mt-5"
      viewBox="0 0 621.069 621.092"
    >
      <g id="Group_16041" data-name="Group 16041" transform="translate(-1298.931 -80)">
        <!-- fifth -->
        <path
          class="animate__animated animate__fadeIn delay-5"
          id="Path_955"
          data-name="Path 955"
          d="M878.768,499.5c-114.288,0-206.951,92.663-206.951,206.961H878.768Z"
          transform="translate(1041.231 -419.498)"
          fill="#e2e5e6"
        />
        <!-- second -->
        <path
          class="animate__animated animate__fadeIn delay-2"
          id="Path_8392"
          data-name="Path 8392"
          d="M878.768,499.5c-114.288,0-206.951,92.663-206.951,206.961H878.768Z"
          transform="translate(627.114 -212.536)"
          fill="#545c63"
        />
        <g
          class="animate__animated animate__fadeIn delay-3"
          id="Group_816"
          data-name="Group 816"
          transform="translate(1506 286.962)"
        >
          <!-- third -->
          <path
            id="Path_956"
            data-name="Path 956"
            d="M921.268,499.5A206.838,206.838,0,0,1,714.336,706.43V689.4c104.724,0,189.911-85.216,189.911-189.906Z"
            transform="translate(-714.336 -499.498)"
            fill="#d3592d"
          />
          <path
            id="Path_957"
            data-name="Path 957"
            d="M886.812,506.584A172.368,172.368,0,0,1,714.336,679.027V661.972c85.712,0,155.451-69.68,155.451-155.388Z"
            transform="translate(-714.336 -506.584)"
            fill="#d3592d"
          />
          <path
            id="Path_958"
            data-name="Path 958"
            d="M852.323,513.664A137.932,137.932,0,0,1,714.336,651.646V634.591A121.081,121.081,0,0,0,835.268,513.664Z"
            transform="translate(-714.336 -513.664)"
            fill="#d3592d"
          />
          <path
            id="Path_959"
            data-name="Path 959"
            d="M817.8,520.756A103.421,103.421,0,0,1,714.336,624.22V607.194a86.545,86.545,0,0,0,86.443-86.438Z"
            transform="translate(-714.336 -520.756)"
            fill="#d3592d"
          />
          <path
            id="Path_960"
            data-name="Path 960"
            d="M783.344,527.848a68.937,68.937,0,0,1-69.008,68.945V579.767a52.025,52.025,0,0,0,51.982-51.919Z"
            transform="translate(-714.336 -527.848)"
            fill="#d3592d"
          />
          <path
            id="Path_961"
            data-name="Path 961"
            d="M748.825,534.928a34.457,34.457,0,0,1-34.489,34.485V552.392A17.474,17.474,0,0,0,731.8,534.928Z"
            transform="translate(-714.336 -534.928)"
            fill="#d3592d"
          />
        </g>
        <!-- first -->
        <g
          class="animate__animated animate__fadeIn delay-1"
          id="Group_16003"
          data-name="Group 16003"
          transform="translate(1299.167 494.16)"
        >
          <path
            id="Path_956-2"
            data-name="Path 956"
            d="M714.336,499.5A206.838,206.838,0,0,0,921.268,706.43V689.4c-104.724,0-189.911-85.216-189.911-189.906Z"
            transform="translate(-714.336 -499.498)"
            fill="#e2e5e6"
          />
          <path
            id="Path_957-2"
            data-name="Path 957"
            d="M714.336,506.584A172.368,172.368,0,0,0,886.812,679.026V661.972c-85.712,0-155.451-69.68-155.451-155.388Z"
            transform="translate(-679.881 -506.584)"
            fill="#e2e5e6"
          />
          <path
            id="Path_958-2"
            data-name="Path 958"
            d="M714.336,513.664A137.932,137.932,0,0,0,852.323,651.646V634.591A121.081,121.081,0,0,1,731.391,513.664Z"
            transform="translate(-645.391 -513.664)"
            fill="#e2e5e6"
          />
          <path
            id="Path_959-2"
            data-name="Path 959"
            d="M714.336,520.756A103.421,103.421,0,0,0,817.8,624.22V607.194a86.545,86.545,0,0,1-86.443-86.438Z"
            transform="translate(-610.873 -520.756)"
            fill="#e2e5e6"
          />
          <path
            id="Path_960-2"
            data-name="Path 960"
            d="M714.336,527.848a68.937,68.937,0,0,0,69.008,68.945V579.767a52.025,52.025,0,0,1-51.982-51.919Z"
            transform="translate(-576.412 -527.848)"
            fill="#e2e5e6"
          />
          <path
            id="Path_961-2"
            data-name="Path 961"
            d="M714.336,534.928a34.457,34.457,0,0,0,34.489,34.485V552.392a17.474,17.474,0,0,1-17.464-17.464Z"
            transform="translate(-541.894 -534.928)"
            fill="#e2e5e6"
          />
        </g>
        <g
          class="animate__animated animate__fadeIn delay-4"
          id="Group_16001"
          data-name="Group 16001"
          transform="translate(1713.049 286.962)"
        >
          <!-- fourth -->
          <g
            id="Group_785"
            data-name="Group 785"
            class="animate__animated animate__fadeIn delay-4"
          >
            <path
              id="Path_909"
              data-name="Path 909"
              d="M775.291,491.05a69.4,69.4,0,1,0,69.4,69.4,69.485,69.485,0,0,0-69.4-69.4m0-34.071A103.476,103.476,0,1,1,671.813,560.452,103.473,103.473,0,0,1,775.291,456.979Z"
              transform="translate(-671.813 -456.979)"
              fill="#485258"
            />
          </g>
          <ellipse
            id="Ellipse_28"
            data-name="Ellipse 28"
            cx="34.492"
            cy="34.492"
            rx="34.492"
            ry="34.492"
            transform="translate(68.985 68.983)"
            fill="#485258"
          />
        </g>
        <!-- sixth -->
        <path
          class="animate__animated animate__fadeIn delay-6"
          id="Path_8390"
          data-name="Path 8390"
          d="M817.692,414.46a103.358,103.358,0,1,1-103.36,103.355A103.355,103.355,0,0,1,817.692,414.46Zm0,137.81a34.453,34.453,0,1,0-34.455-34.455A34.451,34.451,0,0,0,817.692,552.27Z"
          transform="translate(792.001 -334.46)"
          fill="#485258"
        />
      </g>
    </svg>
    <div


      class="h-45vh pt-xl-5 container-fluid animate__animated animate__fadeIn animate__delay-1s"
    >
      <div class="row" v-if="!mobile">
        <div class="col-11 mx-auto">
          <div class="d-flex w-100 justify-content-between">
            <img
              src="../../assets/img/menu.svg"
              class="w-3 pointer"
              @click="this.$store.commit('toggle')"
              alt="menu icon"
            />
          </div>
        </div>
      </div>
      <div class="row" v-if="mobile">
        <div class="col-10 mx-auto mt-sm-6 text-center">
          <img
            src="../../assets/img/mobile-start.svg"
            class="top-image"
            alt="althaalab almacker pattern"
          />
        </div>
      </div>
    </div>
    <div
      class="container-fluid h-55vh w-100 d-flex flex-column justify-content-between py-ds-4-sm-0"
    >
      <div class="row w-100 position-relative mx-0">
        <div class="col-11 mx-auto d-flex align-items-end justify-content-between">
          <div class="text animate__animated animate__fadeInUp animate__delay-1s" v-if="mobile">
            <p
              class="f-80 black white mb-0 line-height-ds-1-sm-3 text-uppercase ar-text-right"
            >
              {{ lang == "ar" ?  "إيجاد الفرص" : "FINDING" }}
            </p>
            <p
              class="f-80 black orange mb-0 line-height-ds-1-sm-3 text-uppercase ar-text-right ar-style"
            >
              {{ lang == "ar" ? "في" : "OPPORTUNITIES" }}
            </p>
            <p
              class="f-80 black white mb-0 position-relative line-height-ds-1-sm-3 text-uppercase ar-text-right ar-w-100"
            >
              {{ lang == "ar" ? "" : "IN EVERY CHALLENGE." }}
              <span class="hidden" v-if="lang=='ar'">كل تحدي</span>
              <p
              v-if="lang=='ar'"
              class="f-80 black white t-0 b-0 mb-0 line-height-ds-1-sm-3 text-uppercase ar-text-right position-absolute float-text"
            >
              {{ lang == "ar" ? "كل تحدي" : "IN EVERY CHALLENGE." }}
            </p>
            </p>
           
          </div>
          <div class="text animate__animated animate__fadeInUp animate__delay-1s" v-else>
            <p
              class="f-80 black white mb-0 line-height-ds-1-sm-3 text-uppercase ar-text-right"
            >
              {{ lang == "ar" ? "إيجاد الفرص  في" : "FINDING" }}
            </p>
            <p
              class="f-80 black orange mb-0 line-height-ds-1-sm-3 text-uppercase ar-text-right ar-style"
            >
              {{ lang == "ar" ? "كل تحدي" : "OPPORTUNITIES" }}
            </p>
            <p
              class="f-80 black white mb-0 line-height-ds-1-sm-3 text-uppercase ar-text-right"
            >
              {{ lang == "ar" ? "" : "IN EVERY CHALLENGE." }}
            </p>
          </div>
          <img
            src="../../assets/img/next.svg"
            class="w-60px animate__animated animate__fadeIn animate__delay-1s pointer next"
            :id="lang + '-next'"
            @click="scroll(lang + '-process')"
            v-if="!mobile"
            alt="next icon"
          />
        </div>
      </div>
      <div class="row w-100 mt-10vh mx-0">
        <div class="col-11 mx-auto">
          <div
            class="ar-ltr d-flex w-100 justify-content-between align-items-center animate__animated animate__fadeInUp animate__delay-1s"
          >
            <img
              class="w-15"
              src="../../assets/img/logo.svg"
              alt="althaalab almacker logo"
              v-if="!mobile"
            />
            <p
              class="mb-0 line-height-1 mulish f-25 regular white mt-md-4 mt-sm-4 ar-text-right"
            >
            {{ lang == "ar" ? "نحن نعرف كيفية توفير الدعم عالي" : "We know how to provide" }}
              <br v-if="mobile" />
            {{ lang == "ar" ? " الجودة ونمو الأعمال" : "high-quality" }}
              <br v-if="!mobile" />
              {{ lang == "ar" ? "" : "support and" }}
              <br v-if="mobile" />
              {{ lang == "ar" ? "" : "business growth." }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    lang: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      screen: window.innerWidth,
    };
  },
  methods: {
    scroll(id) {
      $("main").scrollTo(`#${id}`, 1000);
    },
    onResize() {
      this.screen = window.innerWidth;
    },
  },
  computed: {
    mobile() {
      return this.screen <= 1199.98;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style scoped>
.arabic #en-home {
  order: -1;
  direction: ltr;
}
.english #ar-home {
  order: -1;
  direction: rtl;
}
.ar .next {
  transform: scaleX(-1);
}
.section.ar {
  direction: rtl;
}
.ar .text-right {
  text-align: right;
}
.ar .ar-style {
  padding-right: 82%;
  white-space: nowrap;
  line-height: initial;
}
.ar svg.r-0 {
  right: auto;
  left: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.ar .ar-text-right {
  text-align: right;
}
.ar .line-height-1 {
  line-height: 1.2;
}
/* .ar .ar-ltr {
  direction: ltr !important;
} */
svg .animate__animated {
  animation-duration: 0.2s;
}
.animate__delay-1s {
  animation-delay: 0.5s;
}
.animate__animated {
  animation-duration: 0.8s;
}
.text.animate__animated {
  animation-duration: 0.7s;
}

.delay-1 {
  animation-delay: 1.6s !important;
}
.delay-2 {
  animation-delay: 2.2s;
}
.delay-3 {
  animation-delay: 2.8s;
}
.delay-4 {
  animation-delay: 3.4s;
}
.delay-5 {
  animation-delay: 4s;
}
.delay-6 {
  animation-delay: 4.6s;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.hidden{
  visibility: hidden;
}
@media (max-width: 1199.98px) {
  .arabic .float-text{
    right: 100%;
    white-space: nowrap;
    top: 16%;
  }
  .arabic .ar-w-100{
    width: 100%;
  }
  .arabic .f-80 {
    font-size: 69px;
    line-height: 1.2;
}
.ar .ar-style {
  padding-right: 0%;
  text-align: left!important;
  white-space: nowrap;
  line-height: 1.2;
}
}
@media (max-width: 767.98px){
  .arabic .float-text{
    right:75%;
  }
  .arabic .f-80 {
    font-size: 40px;
    line-height: 1.2;
}
}
</style>
