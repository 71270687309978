<template>
  <div
    class="container-fluid position-fixed z-3 menu-container"
    :class="
      !isMenu
        ? 'animate__animated animate__slideOutLeft unactive'
        : 'active animate__animated animate__slideInLeft'
    "
  >
    <div class="row position-relative">
      <div class="col-xl-9 col-12 px-0 position-relative z-2">
        <div class="menu z-3" id="menu">
          <div class="row h-100 mx-0">
            <div
              class="col-xl-11 col-12 offset-1 d-flex align-items-center justify-content-start"
            >
              <div
                class="vertical-line animate__delay-2s animate__animated animate__fadeIn ar-mr-5"
              ></div>
              <div
                class="vertical-line ms-1 animate__delay-2s animate__animated animate__fadeIn"
              ></div>
              <div
                class="links-list d-flex flex-column align-items-start justify-content-between ms-5 en-me-5 ar-me-5 animate__delay-1s animate__animated animate__fadeInLeft"
              >
                <p
                  @click="activeLinke('management')"
                  class="mulish f-50 bold dark-blue internal-link pointer w-100 line-height-3 text-uppercase"
                >
                  {{ $t("second-tab1-1") }} {{ $t("second-tab1-2") }}
                </p>
                <p
                  @click="activeLinke('digital')"
                  class="mulish f-50 bold dark-blue internal-link pointer w-100 line-height-3 text-uppercase"
                >
                  {{ $t("second-tab2-1") }} {{ $t("second-tab2-2") }}
                </p>
                <p
                  @click="activeLinke('branding')"
                  class="mulish f-50 bold dark-blue internal-link pointer w-100 line-height-3 text-uppercase"
                >
                  {{ $t("second-tab3-1") }} {{ $t("second-tab3-2") }}
                </p>
                <p
                  @click="
                    this.$store.commit('toggle');
                    scroll($root.$i18n.locale + '-process');
                  "
                  class="mulish f-50 bold dark-blue internal-link pointer w-100 text-uppercase"
                >
                  {{ $t("menu-process") }}
                </p>

                <p
                  @click="
                    this.$store.commit('toggle');
                    scroll('about-us');
                  "
                  class="mulish f-50 bold dark-blue internal-link pointer w-100 text-uppercase"
                >
                  {{ $t("menu-about") }}
                </p>

                <p
                  @click="
                    this.$store.commit('toggle');
                    scroll('contact');
                  "
                  class="mulish f-50 bold dark-blue internal-link pointer w-100 text-uppercase"
                >
                  {{ $t("menu-contact") }}
                </p>
              </div>
              <div
                class="lang-changer d-block d-xl-none bold orange f-50 text-center w-100 h-80-p"
              >
                <p class="mb-0 line-height-1" id="changer" @click="changeLocale2(switchTo)">AR</p>
              </div>
              <div
                class="text-center w-mobile-0 w-xl-100 animate__delay-2s animate__animated animate__fadeIn position-relative"
              >
                <img
                  src="../../assets/img/go-back.svg"
                  id="close-menu"
                  class="w-18 pointer mobile-back"
                  alt="back icon"
                  @click="this.$store.commit('toggle')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="position-absolute bg-light-grey-bold t-0 b-0 l-0 r-0 opacity z-1 d-xl-block d-none"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      first: true,
      currentLang: "en",
      switchTo: "ar",
    };
  },
  mounted() {
    $(".menu-container").addClass("d-none");
  },
  computed: {
    isMenu() {
      if (this.$i18n.locale != this.currentLang) {
        $(".menu-container").addClass("d-none");
        this.currentLang = this.$i18n.locale;
      } else $(".menu-container").remove("d-none");
      return this.$store.getters.getMenu;
    },
    lang() {},
  },
  methods: {
    changeLocale2(localeCode) {
      // this.$store.commit('toggle')
      this.currentLang = localeCode;
      this.$emit(localeCode);
      if (localeCode == "en") {
        this.switchTo = "ar";
        document.getElementById("changer").innerText = "AR";
      } else {
        this.switchTo = "en";
        document.getElementById("changer").innerText = "EN";
      }
    },
    scroll(id) {
      // var shift = (7*window.innerWidth)/100;
      $("main").scrollTo(`#${id}`, 500);
    },
    activeLinke(id) {
      if (id == "management") {
        this.$store.commit("toggle");
        // this.scroll('process');
        // setTimeout(function(){document.getElementById("management-btn").click()}, 1000);
        document.getElementById("management-btn").click();
      } else if (id == "digital") {
        this.$store.commit("toggle");
        document.getElementById("digital-btn").click();
      } else if (id == "branding") {
        this.$store.commit("toggle");
        document.getElementById("branding-btn").click();
      }
    },
  },
};
</script>
<style scoped>
.arabic .text-end {
  text-align: left !important;
}

.arabic #close-menu {
  transform: scaleX(-1);
}

.internal-link {
  width: fit-content !important;
}

.arabic .active.animate__slideInLeft {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.arabic .unactive.animate__slideOutLeft {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

.ar {
  direction: rtl;
}

.ar,
.arabic p,
.ar .mulish {
  font-family: "Cairo", sans-serif;
}

.arabic .ar-me-5 {
  margin-right: 3rem !important;
  margin-left: 0rem !important;
}

.arabic .ar-mr-5 {
  margin-right: 3rem;
  margin-left: 0.25rem;
}

.arabic .menu {
  border-radius: 540px 0px 0px 540px;
}

.ar .ar-me-5 {
  margin-right: 3rem !important;
  margin-left: 0rem !important;
}

.opacity {
  opacity: 0;
}

.active .opacity {
  opacity: 1;
  transition: all 0.6s;
}

.bg-light-grey-bold {
  background-color: #373e43d9;
  backdrop-filter: blur(4px);
}

.pointer {
  cursor: pointer;
}

.animate__delay-1s {
  --animate-delay: 1s;
}

.w-18 {
  width: 18%;
}

.menu {
  background-color: #f3f3f3;
  border-radius: 0 540px 540px 0px;
  height: 100vh;
  width: 100%;
}

.z-3 {
  z-index: 3;
}

.vertical-line {
  border-left: 1px solid #262b30;
  height: 80%;
  width: 0;
}

.dark-blue {
  color: #262b30;
}

.links-list {
  height: 80%;
  width: inherit;
}

a {
  text-decoration: none !important;
}

.internal-link {
  padding-left: 0px;
  padding-right: 0px;
  transition: all 0.3s ease-in-out;
}

.arabic .internal-link:hover {
  color: #d6572b;
  padding-right: 50px;
  transition: all 0.3s ease-in-out;
}

.english .internal-link:hover {
  color: #d6572b;
  padding-left: 50px;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1199.98px) {
  .arabic .ar-me-5 {
    margin-right: 1rem !important;
    margin-left: 0rem !important;
  }

  .english .en-me-5 {
    margin-left: 1rem !important;
    margin-right: 0rem !important;
  }

  .internal-link.f-50 {
    font-size: 22px;
  }
}
</style>
