<template>
    <div class="bg-blur-black position-fixed t-0 b-0 l-0 r-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center" id="hint">
     <div class="mouse">
  <span></span>
</div>
    <p class="white mulish text text-capitalize semi-bold text-center">Scroll to see<br/>our site content</p>
    </div>
</template>
<style scoped>
.text{
    margin-top: 25vh;
    font-size: 20px;
    line-height: 1.3;
}
.bg-blur-black{
    background-color: #10101056 !important;
        backdrop-filter: blur(4px) !important;
        z-index: 11;
}
.mouse {
	 display: block;
	 margin: 0 auto;
	 width: 23px;
	 height: 40px;
	 border-radius: 13px;
	 border: 2px solid #ffffff;
	 position: absolute;
	 top: 50%;
	 position: absolute;
	 left: 50%;
	 margin-left: -14px;
}
 .mouse span {
	 display: block;
	 margin: 6px auto;
	 width: 3px;
	 height: 7px;
	 border-radius: 100%;
	 background: #ffffff;
	 -webkit-animation-duration: 1s;
	 animation-duration: 1s;
	 -webkit-animation-fill-mode: both;
	 animation-fill-mode: both;
	 -webkit-animation-iteration-count: infinite;
	 animation-iteration-count: infinite;
	 -webkit-animation-name: scroll;
	 animation-name: scroll;
}
 @-webkit-keyframes scroll {
	 0% {
		 opacity: 1;
		 -webkit-transform: translateY(0);
		 transform: translateY(0);
	}
	 100% {
		 opacity: 0;
		 -webkit-transform: translateY(12px);
		 transform: translateY(12px);
	}
}
 @keyframes scroll {
	 0% {
		 opacity: 1;
		 -webkit-transform: translateY(0);
		 -ms-transform: translateY(0);
		 transform: translateY(0);
	}
	 100% {
		 opacity: 0;
		 -webkit-transform: translateY(12px);
		 -ms-transform: translateY(12px);
		 transform: translateY(12px);
	}
}
 html {
	 width: 100%;
	 height: 100%;
	 background: radial-gradient(circle, #fff 0%, #aaa 100%) no-repeat;
	 overflow-x: hidden;
	 overflow-y: hidden;
}
 body {
	 text-align: center;
	 display: table;
	 width: 100%;
	 height: 100%;
	 overflow-x: hidden;
	 overflow-y: hidden;
}
</style>
<script>
export default {
    mounted(){
        setTimeout(function(){
            $('#hint').addClass('d-none')
        },3000)
    }
}
</script>