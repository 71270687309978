<template>
  <div class="section bg-light-grey" :class="$root.$i18n.locale" :id="$root.$i18n.locale+'-process'"
  >
    <div class="d-flex w-100 h-100 flex-mobile-column ar-mb-4">
      <div class="w-7vw" v-if="!mobile">
        <Sidebar />
      </div>
      <div class="w-31vw">
        <div
          class="service w-fit-content mx-auto h-100 w-100 text-center d-flex flex-ds-column-sm-reverse justify-content-center align-items-center pointer"
          @click="management = true"
          id="management-btn"
        >
          <div
            class="title line-height-3 f-50 mulish black text-uppercase text-xl-start text-center w-fit-content mx-auto mt-sm-4"
          >
            {{ $t("second-tab1-1") }}<br class="ar-none" />
            {{ $t("second-tab1-2") }}
          </div>
          <div
            class="icon mt-5 h-64 d-flex justify-content-end flex-mobile-row-ds-column"
          >
            <picture class="h-ds-50 icon-top">
              <source
                media="(max-width: 763.98px)"
                srcset="../../assets/img/management-consulting-mobile-1.svg"
              />
              <!-- <source media="(min-width: 465px)" srcset="img_car.jpg"> -->
              <img
                src="../../assets/img/management-consulting-1.svg"
                class="h-100"
                alt="management consulting"
              />
            </picture>
            <picture class="h-ds-50 icon-bottom">
              <source
                media="(max-width: 763.98px)"
                srcset="../../assets/img/management-consulting-mobile-2.svg"
              />
              <!-- <source media="(min-width: 465px)" srcset="img_car.jpg"> -->
              <img
                src="../../assets/img/management-consulting-2.svg"
                class="h-100"
                alt="management consulting"
              />
            </picture>
          </div>
        </div>
      </div>
      <div class="w-31vw border-x-1px">
        <div
          class="service w-fit-content mx-auto h-100 w-100 text-center d-flex flex-ds-column-sm-reverse justify-content-center align-items-center pointer"
          @click="digital = true"
          id="digital-btn"
        >
          <div
            class="title line-height-1 f-50 mulish black text-uppercase text-xl-start text-center w-fit-content mx-auto mt-sm-4"
          >
            {{ $t("second-tab2-1") }}<br class="ar-none" />
            {{ $t("second-tab2-2") }}
          </div>
          <div
            class="icon mt-5 h-64 d-flex flex-mobile-row-ds-column justify-content-end"
          >
            <picture class="h-ds-50 icon-top">
              <source
                media="(max-width: 763.98px)"
                srcset="../../assets/img/digital-solutions-mobile-1.svg"
              />
              <!-- <source media="(min-width: 465px)" srcset="img_car.jpg"> -->
              <img
                src="../../assets/img/digital-solutions-1.svg"
                class="h-100"
                alt="digital solutions"
              />
            </picture>
            <picture class="h-ds-50 icon-bottom">
              <source
                media="(max-width: 763.98px)"
                srcset="../../assets/img/digital-solutions-mobile-2.svg"
              />
              <!-- <source media="(min-width: 465px)" srcset="img_car.jpg"> -->
              <img
                src="../../assets/img/digital-solutions-2.svg"
                class="h-100"
                alt="digital solutions"
              />
            </picture>
          </div>
        </div>
      </div>
      <div class="w-31vw">
        <div
          class="service w-100 h-100 w-fit-content mx-auto text-center d-flex flex-ds-column-sm-reverse justify-content-center align-items-center pointer"
          @click="branding = true"
          id="branding-btn"
        >
          <div
            class="title line-height-3 f-50 mulish black text-uppercase text-xl-start text-center w-fit-content mx-auto mt-sm-4"
          >
            {{ $t("second-tab3-1") }}<br class="ar-none" />
            {{ $t("second-tab3-2") }}
          </div>
          <div
            class="icon mt-5 h-64 d-flex flex-mobile-row-ds-column justify-content-end"
          >
            <picture class="h-ds-50 icon-top">
              <source
                media="(max-width: 763.98px)"
                srcset="../../assets/img/brand-communication-mobile-1.svg"
              />
              <!-- <source media="(min-width: 465px)" srcset="img_car.jpg"> -->
              <img
                src="../../assets/img/brand-communication-1.svg"
                class="h-100"
                alt="brand communication"
              />
            </picture>
            <picture class="h-ds-50 icon-bottom">
              <source
                media="(max-width: 763.98px)"
                srcset="../../assets/img/brand-communication-mobile-2.svg"
              />
              <!-- <source media="(min-width: 465px)" srcset="img_car.jpg"> -->
              <img
                src="../../assets/img/brand-communication-2.svg"
                class="h-100"
                alt="brand communication"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
  <service_latout
    v-if="management"
    @clicked-show-detail="management = false"
    :title="$t('second-tab1-1') + ' ' + $t('second-tab1-2')"
    :desc="$t('second-tab1-desc')"
    :imgSrc="getImg1"
    :list="[
      $t('second-tab1-list1'),
      $t('second-tab1-list2'),
      $t('second-tab1-list3'),
      $t('second-tab1-list4'),
      $t('second-tab1-list5'),
      $t('second-tab1-list6'),
    ]"
  />
  <service_latout
    v-if="digital"
    @clicked-show-detail="digital = false"
    :title="$t('second-tab2-1') + ' ' + $t('second-tab2-2')"
    :desc="$t('second-tab2-desc')"
    :imgSrc="getImg2"
    :list="[
      $t('second-tab2-list1'),
      $t('second-tab2-list2'),
      $t('second-tab2-list3'),
      $t('second-tab2-list4'),
      $t('second-tab2-list5'),
      $t('second-tab2-list6'),
    ]"
  />
  <service_latout
    @clicked-show-detail="branding = false"
    v-if="branding"
    :title="$t('second-tab3-1') + ' ' + $t('second-tab3-2')"
    :desc="$t('second-tab3-desc')"
    :imgSrc="getImg3"
    :list="[
      $t('second-tab3-list1'),
      $t('second-tab3-list2'),
      $t('second-tab3-list3'),
      $t('second-tab3-list4'),
      $t('second-tab3-list5'),
      $t('second-tab3-list6'),
    ]"
  />
</template>
<script>
import Sidebar from "../shared/sidebar.vue";
import service_latout from "../shared/service_latout.vue";

export default {
  data() {
    return {
      changed: false,
      management: false,
      digital: false,
      branding: false,
      screen: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.screen = window.innerWidth;
    },
  },
  computed: {
    mobile() {
      return this.screen <= 1199.98;
    },
    // management
    getImg1() {
      return window.innerWidth > 1199.98
        ? "management-consulting-side.svg"
        : "management.svg";
    },
    // digital
    getImg2() {
      return window.innerWidth > 1199.98 ? "digital-solutions-side.svg" : "digital.svg";
    },
    // brand
    getImg3() {
      return window.innerWidth > 1199.98
        ? "brand-communication-side.svg"
        : "branding.svg";
    },
  },
  components: { Sidebar, service_latout },
};
</script>
<style scoped>
.ar .ar-none {
  display: none;
}
.section.ar {
  direction: rtl;
}
.max-width-100 {
  max-width: 100%;
}
.max-width-60px {
  max-width: 60px;
}
.service {
  cursor: pointer;
}
.h-ds-50 {
  height: 50%;
}
.flex-ds-column-sm-reverse {
  flex-direction: column;
}
@media (min-width: 1200px) {
  .service .icon .icon-top,
  .service .icon .icon-bottom {
    transition: all 0.6s ease-in-out;
  }
  .service:hover .icon .icon-top {
    transform: translate(0px, 100%);
    transition: all 0.6s ease-in-out;
  }
  .service:hover .icon .icon-bottom {
    transform: translate(0px, -100%);
    transition: all 0.6s ease-in-out;
  }
}
@media (max-width: 1199.98px) {
  .ar .ar-mb-4{
    margin-bottom: 2rem;
  }
  .h-ds-50 {
    height: unset;
    width: auto;
  }
  .w-31vw {
    width: 66%;
    margin: 2.5vh auto;
  }
  .f-50 {
    font-size: 30px;
  }
  .ar .f-50 {
    font-size: 25px;
    font-weight: 500!important;
  }
  .flex-ds-column-sm-reverse {
    flex-direction: column-reverse;
  }
}
</style>
