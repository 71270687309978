import { createStore } from 'vuex'

export default createStore({
  state: {
    menu : false 
    },
  getters: {
     getMenu(state){
      return state.menu;
    },
  },
  mutations: {
    toggle(state){
      state.menu  = !state.menu;
    },
    
  },
  actions: {
  },
  modules: {
  }
})
