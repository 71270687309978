<template>
    <div class="w-33 d-flex flex-ds-column-mobile-reverse ml-71 id-card mt-5 mt-xl-0 hidden">
        <p class="f-30 mulish light dark-blue d-xl-none d-block mx-auto mb-0">
            {{ bref }}
        </p>
        <div class="id">
            <p class="f-35 semi-bold mulish dark-blue mb-0">{{ position }}</p>
            <p class="f-35 light mulish dark-blue line-height-1 mb-xl-3 mb-2">{{ name }}</p>
        </div>
        <div class="position-relative overflow-hidden">
            <div class="overlay-black position-absolute b-0 l-0 r-0 t-0 d-flex align-items-end  d-xl-block d-none">

            </div>
            <p class="f-30 mulish light white text  position-absolute b-0 w-90 mx-auto l-0 r-0  d-xl-block d-none">
                {{ bref }}
            </p>
            <img :src="require(`@/assets/img/${imgSrc}`)" class="w-100" :alt="name">
        </div>
    </div>
</template>
<script>

export default {
    props: {
        position: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        imgSrc: {
            type: String,
            default: ''
        },
        bref: {
            type: String,
            default: ''
        }
    },
    setup() {

    },
    mounted() {

    }
}
</script>
<style scoped>
.flex-ds-column-mobile-reverse {
    flex-direction: column;
}

.w-90 {
    width: 90%;
}

.overlay-black {
    background-color: #2b3339bc;
    background-blend-mode: hard-light;
    transform: translateY(-100%);
    opacity: 0;
    z-index: -1;
    transition: all .6s ease-in-out;
}

.text {
    transform: translateY(100%);
    opacity: 0;
    transition: all .6s ease-in-out;
    z-index: -1;
}

.id-card:hover .overlay-black,
.id-card:hover .text {
    transform: translateY(0%);
    display: block;
    opacity: 1;
    transition: all .3s ease-in-out;
    z-index: 0;
}



.dark-blue {
    color: #2B3339;
}

.ml-71 {
    margin-left: 5vw;
}

@media(max-width: 1199.98px) {
    .hidden {
    visibility: hidden;
}
    .id .semi-bold {
        font-weight: 700 !important;
        font-size: 30px;
    }

    .id .light {
        font-weight: 500 !important;
        font-size: 25px;
    }

    .w-33 {
        width: 75%;
        margin-left: auto !important;
        margin-right: auto;
    }

    .ml-71 {
        margin-left: 0vw;
    }

    .flex-ds-column-mobile-reverse {
        flex-direction: column-reverse;
    }
}</style>