<template>
    <div class="container-fluid bg-light-black " id="mobile-header">
        <div class="row my-auto h-13vh align-items-center">
        <div class="col-11 mx-auto">
            <div class="d-flex w-100 justify-content-between">
                <img src="../../assets/img/menu.svg" alt="menu icon" class="w-3 pointer" @click="this.$store.commit('toggle')" />
                <img class="w-15" src="../../assets/img/logo.svg" alt="althaalab almacker logo"/>
            </div>
        </div>
    </div>
    </div>

</template>
<style scoped>
.h-13vh{
    height: 14vh;
}
@media (max-width: 414.98px){
    .h-13vh{
        height: 11vh;
    }
   
}
</style>