<template>
  <div class="addresses-container mt-xl-0 mt-5">
    
    <div class="ar-mobile-w-min-content d-flex flex-column mr-10vw align-items-start ar-mobile-align-items-center mt-xl-3">
      <a href="mailto:info@tms.sy" class="white f-25 regular mulish just-mulish">info@tms.sy</a>
      <a
        href="https://althaalab.madfoxhosting.com/"
        class="white f-25 regular mulish mb-4 just-mulish"
        >althaalabAlmacker.com</a
      >

      <a href="tel:+971 50 955 9785" class="white f-25 regular mulish ltr just-mulish"
        >Tel. +971 50 955 9785</a
      >
      <a href="tel:+971 50 955 9785" class="white f-25 regular mulish mb-4 ltr just-mulish"
        >Cel. +971 50 955 9785</a
      >

      <a href="#">
        <p
          class="white f-25 regular mulish mb-xl-4 mb-4 "
        >
        {{ $t('sixth-addresses-location-1') }}<br/>
        {{ $t('sixth-addresses-location-2') }}<br/>
        {{ $t('sixth-addresses-location-3') }}<br/>
        {{ $t('sixth-addresses-location-4') }}
        </p>
      </a>
    </div>
  </div>
</template>
<script>
export default {
};
</script>
<style scoped>
.ltr {
  direction: ltr;
}
.ar .mr-10vw {
  margin-left: 10vw;
  margin-right: 0px;
}
.mr-10vw {
  margin-right: 10vw;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #f3f3f3;
}
.align-items-ds-start-sm-center {
  align-items: start;
}
@media (max-width: 1199.98px) {
  .ar .ar-mobile-w-min-content{
    width: min-content;
  }
  .ar .ar-mobile-align-items-center{
    align-items: center!important;
  }
  .ar  .mr-10vw{
    margin-right: 0!important;
    margin-left: 0!important;
  }
  .addresses-container .regular {
    font-weight: 500;
  }
  .addresses-container {
    background-color: white;
    border: 1px solid white;
    border-radius: 91%;
    height: 100vw;
    width: 100vw;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10px;
  }
  .addresses-container .white {
    color: #9e2600 !important;
  }
  .align-items-ds-start-sm-center {
    align-items: center;
  }
  .text-align-mobile-center {
    text-align: center;
  }
  .mr-10vw {
    margin: auto;
    width: fit-content;
  }
}
</style>
