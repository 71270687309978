
<template>
  <div   :class="$root.$i18n.locale" id="about-us" class="section bg-light-grey ml-9">
    <!-- <div class="d-none" id="lang-switcher" v-if="(this.$i18n.locale = this.lang)=='ab'">
    </div> -->
    <div  class="d-flex align-items-center flex-mobile-column h-100 w-mobile-90">
      <p class="mulish dark-blue extra-bold f-130 d-xl-none d-block line-height-1 text-start position-relative text-uppercase mb-0 grey-title">
       {{$t("fifth-title1-1")}} 
       <br/>
       {{$t("fifth-title1-2")}}
      </p>
      <p class="mulish dark-blue extra-bold f-130 d-xl-none d-block line-height-1 text-end position-relative text-uppercase mb-0 grey-title ar-mobile-none"
        >
        {{ $t('fifth-title3-1') }}<br/>
        {{ $t('fifth-title3-2') }}

      </p>
      <p class="us black mulish f-250 text-start d-xl-none d-block  position-relative text-uppercase mb-0">
        {{ $t("fifth-title2") }}
      </p>
      <h2
        class="mulish dark-blue extra-bold f-130 line-height-1 text-end position-relative text-uppercase d-xl-block d-none">
        <p>
          {{$t("fifth-title1-1")}} 
       <br/>
       {{$t("fifth-title1-2")}}
       <br class="ar-none"/>
       {{ $t('fifth-title3-1') }}<br class="ar-none"/>
        {{ $t('fifth-title3-2') }}
        </p>
        <p class="us black mulish f-250 position-relative">{{ $t("fifth-title2") }}</p>

      </h2>

      <div class="cards d-flex  flex-mobile-column  mx-29 mb-xl-5">
        <idCard
         :position="$t('fifth-mostafa-position')" :name="$t('fifth-mostafa-name')"
          imgSrc="CEO-Moustapha-Al-Baghdadi.svg" :bref="$t('fifth-mostafa-desc')" />
        <idCard :position="$t('fifth-moaiad-position')" :name="$t('fifth-moaiad-name')" imgSrc="CTO-Moaiad-Bashiti.svg" :bref="$t('fifth-moaiad-desc')" />
        <idCard :position="$t('fifth-nazir-position')" :name="$t('fifth-nazir-name')" imgSrc="COO-Nazir-Salem.svg"
        :bref="$t('fifth-nazir-desc')" />
      </div>
    </div>
  </div>
</template>
<script>
import idCard from "../shared/idCard.vue";
export default {
  components: {
    idCard,
  },
};
</script>
<style scoped>
.ar .ar-none{
  display: none;
}
.ar.section {
  direction: rtl;
}

.mx-29 {
  margin-left: 29vw;
  margin-right: 22vw;
}

.ar .mx-29 {
  margin-right: 22vw;
  margin-left: 22vw;
}

.dark-blue {
  color: #2b3339;
}

.ar .us{
    right: 0;
}

.us {
  right: -60%;
  color: #9e2600;
  line-height: .8;
}

.ml-9 {
  margin-left: 9.79vw;
}

.ar.ml-9 {
  margin-right: 9.79vw;
  margin-left: 0vw;
}

@media (max-width: 1199.98px) {
  .ar .ar-mobile-none{
    display: none!important;
  }
  .ar .f-130 ,.ar .us.f-250{
    font-size: 66px;
    line-height: 1.2;
    font-weight: 900!important;
    text-align: start!important;
}
.ar .f-130{
  margin-top: 2rem!important;
}

  .ar.ml-9{
      margin-right: 0;
  margin-left: 0;
  }
  .ar .mx-29 {
  margin-right: 0;
  margin-left: 0;
}
  .grey-title {
    font-weight: 900 !important;
  }

  .w-mobile-90 {
    width: 100%;
    margin: auto;
  }

  .w-mobile-90 p {
    width: 75%;
  }
  .ar .w-mobile-90 p {
    width: 80%;
    margin: auto;
  }
  .ml-9 {
    margin-left: 0vw;
  }

  .mx-29 {
    margin-left: 0vw;
    margin-right: 0vw;
  }

  .f-250 {
    font-size: 100px;
  }

  .f-130 {
    font-size: 70px;
  }

  .us {
    right: 0%;
  }
}</style>