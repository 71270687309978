<template>
  <div
    class="w-7vw bg-light-black d-flex flex-column t-0 l-0 h-100 z-1 sidebar"
    :id="$root.$i18n.locale + '-sidebar'"
    style="position: relative"
  >
    <img
      src="../../assets/img/shortand-logo.svg"
      class="w-50 mt-5 mx-auto max-width-60px pointer"
      alt="althaalab almacker logo "
      @click="scrollTostart"
    />
    <img
      src="../../assets/img/menu.svg"
      class="w-25 m-auto pointer position-absolute t-0 b-0 r-0 l-0"
      @click="this.$store.commit('toggle')"
      alt="menu icon"
    />
  </div>
</template>
<script>
export default {
  props: {
    lan: {
      type: String,
      default: "en",
    },
  },
  mounted() {
    if (window.innerWidth > 1199.98)
      $("main").scroll(function () {
        // var elementPosition = $('.sidebar').offset();
        if (
          document.querySelector("#en-process") &&
          document.querySelector("#en-process").getBoundingClientRect().left <= 0
        ) {
          $("#en-sidebar").css("position", "fixed").css("left", "0").css("z-index", "1");
        } else {
          $("#en-sidebar").css("position", "relative");
        }
        if (
          document.querySelector("#ar-process") &&
          document.querySelector("#ar-process").getBoundingClientRect().left >= 13
        ) {
          $("#ar-sidebar")
            .css("position", "fixed")
            .css("right", "0")
            .css("left", "auto")
            .css("z-index", "1");
        } else {
          $("#ar-sidebar").css("position", "relative");
        }
      });
  },
  methods: {
    scrollTostart() {
      $("main").scrollTo("#en-home", 1000);
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
